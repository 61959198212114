import "./Certifications.css";
import Section from "../../components/Section/Section";
import useScroll from "../../hooks/useScroll";
import { PiCertificateFill } from "react-icons/pi";
import info from "../../data/Info";
import { useEffect, useState } from "react";

const data = info.certifications;
const length = data.length;

const Certifications = () => {
  const [current, setCurrent] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setCurrent((prev) => (prev < length - 1 ? prev + 1 : 0));
      }
    }, 3500);

    return () => {
      clearInterval(interval);
    };
  }, [isHovered]);

  useScroll("hw__certifications", "animate__fadeInUp");

  return (
    <Section
      title="certifications"
      desc="Certified and ready to elevate your next big idea with expertise."
    >
      <div
        className="hw__certifications"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="hw__certifications-list">
          <div className="hw__certifications-header">
            <PiCertificateFill />
            <span>Certificate List</span>
          </div>

          <div className="hw__certifications-list-scroll lenis">
            <ul>
              {data.map((item, index) => (
                <li
                  key={item.name}
                  className={current === index ? "current" : ""}
                  onClick={() => setCurrent(index)}
                  title={item.name}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="hw__certifications-imgs">
          {data.map((item, index) => (
            <img
              key={item.name}
              className={current === index ? "current" : ""}
              src={item.img}
              alt={item.name}
              title={item.name}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Certifications;
