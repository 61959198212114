import React, { useState } from "react";
import "./Projects.css";
import Section from "../../components/Section/Section";
import useScroll from "../../hooks/useScroll";
import LazyLoad from "react-lazyload";
import { FiExternalLink, FiGithub } from "react-icons/fi";
import info from "../../data/Info";
import Button from "../../components/Button/Button";

const Projects = () => {
  useScroll("hw__projects-item", "animate__zoomIn");
  const [selected, setSelected] = useState(0);

  const Tabs = (
    <>
      {info.projects.map((tab, index) => (
        <Button
          onClick={() => setSelected(index)}
          className={selected === index ? "hw__projects-tab-selected" : ""}
          variant="outlined"
          key={`project-tab-${index + 1}`}
        >
          <div>
            {tab.type}

            <span className="hw__projects-tab-sup">
              {info.projects[index].data.length}
            </span>
          </div>
        </Button>
      ))}
    </>
  );

  const ProjectItems = (
    <>
      {info.projects[selected].data.map((project, index) => (
        <div className="hw__projects-item" key={`project-${index + 1}`}>
          <div className="hw__projects-item-card">
            <div className="hw__projects-item-img">
              {project.img ? (
                <LazyLoad once offset={100}>
                  <img src={project.img} alt={project.title} />
                </LazyLoad>
              ) : (
                <div
                  style={{
                    backgroundColor: "var(--color-grey)",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>

            <div className="hw__projects-content">
              <div className="hw__projects-title">{project.title}</div>
              <p className="hw__projects-desc">{project.desc}</p>
              <div className="hw__projects-link">
                <a
                  href={project.demo}
                  alt="demo"
                  target="_blank"
                  rel="noreferrer"
                  className="hw__projects-link-demo"
                >
                  <FiExternalLink />
                </a>
                {project.github && (
                  <a
                    href={project.github}
                    alt="github"
                    target="_blank"
                    rel="noreferrer"
                    className="hw__projects-link-github"
                  >
                    <FiGithub />
                  </a>
                )}
              </div>
            </div>
          </div>

          {project.techs && (
            <div className="hw__projects-item-techs">
              {project.techs.map((tech) => (
                <div className="hw__projects-item-tech" key={tech}>
                  {tech}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );

  return (
    <Section title="projects">
      <div className="hw__projects-tabs">{Tabs}</div>

      <p className="hw__projects-tab-desc">{info.projects[selected].desc}</p>

      <div className="hw__projects-items">{ProjectItems}</div>
    </Section>
  );
};

export default Projects;
